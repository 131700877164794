.page1 {
  height: 75%;
  width: 100%;
}

.page1 h1 {
  margin-bottom: 1rem;
  white-space: nowrap;
}

.page1 h2 {
  margin-bottom: 5vh;
}

.paragraph-page-one {
  color: #000;
  font-family: Inter;
  font-size: 2rem;
  font-weight: 200;
  max-width: 60vw;
  margin-bottom: 5vh;
}

.page1 .sign-up-button {
  position: static;
  top: unset;
  left: 0;
}

.homeArrowText {
  position: absolute;
  left: 38vw;
  top: 55vh;
  font-weight: bold;
  font-size: 2vw;
  color: black;
  font-family: cursive;
}

.homeArrow {
  position: absolute;
  top: 57vh;
  left: 51vw;
  color: black;
  transform: rotate(90deg);
  width: 5vw;
}