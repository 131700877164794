@media screen and (max-width: 660px) {
  .container {
    opacity: 100% !important;
    margin-top: 10vh;
  }

  .menuButton {
    display: block;
    background-color: transparent;
    border: none;
    position: fixed;
    top: 0;
    z-index: 50;
  }
  
  .menuButton svg rect {
    fill: var(--primary);
    opacity: 100%;
    transition: opacity 0.5s ease, transform 0.5s ease, transform-origin 0.5s ease;
  }

  .menuButton.show svg rect:first-child {
    fill: var(--primary);
    transform: rotate(45deg) translate(0, 33px);
    transform-origin: center center;
  }

  .menuButton.show svg rect:nth-child(2) {
    opacity: 0;
  }

  .menuButton.show svg rect:last-child {
    fill: var(--primary);
    transform: rotate(-45deg) translate(0, -30px);
    transform-origin: center center;
  }

  .selected-line,
  .line {
    display: none;
  }

  .navigation-bar {
    transform: translate(-100%);
    opacity: 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: white !important;
    transition: transform 0.5s ease, opacity 0.5s ease;
    z-index: 40;
    justify-content: center;
    gap: 50px;
  }

  .navigation-bar.show {
    transform: translate(0px);
    opacity: 100%;
  }

  .navigation-bar .nav-button {
    font-size: 2rem !important;
    padding: 5px 20px 2px 20px;
    align-items: center;
    align-self: center;
    flex: unset;
    height: auto;
  }

  .navigation-bar .sign-up-button {
    font-size: 1.8rem;
  }

  .nextCircle,
  .nextBtn.majors {
    font-size: 1rem;
    width: 15rem;
    height: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    top: 90vh !important
  }

  .nextBtn.majors {
    top: 83vh !important;
  }

  .nextCircle .arrow {
    width: 1rem;
    height: 1rem;
  }

  .nextCircle svg {
    top: 0.2rem;
  }

  .page {
    width: calc(100% - 20px) !important;
    top: 40px !important;
    padding: 0 10px 0 10px !important;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .page1 h1 {
    white-space: unset !important;
  }

  .page1 h2 {
    margin-bottom: 1rem !important;
  }

  .body,
  .paragraph-page-one,
  .page3 .paragraph {
    font-size: 1rem !important;
  }

  .paragraph-page-one {
    max-width: 100% !important;
  }

  .image-circle-one,
  .page3 .image {
    width: 40vw !important;
    height: 40vw !important;
    top: 60vh;
    left: 30vw;
  }

  .circle {
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vw;
    position: fixed;
  }

  .sign-up-button {
    left: 20px;
    top: 45vh;
    font-size: 1rem;
    padding: 10px 20px;
  }

  .title-page-two,
  .title-page-three {
    width: 100vw;
    left: 0;
    text-align: center;
    top: 0vh;
  }

  .info-box {
    margin: 0;
    padding: 10px;
    max-height: 20vh;
    overflow: hidden;
  }

  .info-box .info-title {
    font-size: 1.2rem !important;
  }

  .info-box .info-text {
    font-size: 1rem;
  }

  .page3 .content {
    margin-left: 0 !important;
  }

  .overlay div {
    font-size: 4vw;
  }

  .homeArrow,
  .homeArrowText {
    display: none;
  }

  .page2 {
    padding: 0 !important;
  }

  .page2 .sections {
    height: 75vh !important;
    margin-top: 20px;
  }

  .page2 .info-box {
    width: 30vw !important;
  }

  .page2 .page-two-grid {
    grid-template-rows: repeat(2, 60%) !important;
    padding: 0 10px 0 10px;
  }

  .page2 .overlay h2 {
    text-align: center;
  }

  .page2 .overlay .body {
    font-size: 1rem !important;
    padding: 0 10px 0 10px !important;
  }

  .page2 .overlay svg {
    width: 1.5rem;
  }

  .page2 .nextBtn {
    font-size: 1rem;
    top: 31vh;
  }

  .page2 .majors {
    bottom: 10px !important;
    left: 30vw !important;
  }

  .page2 .majors h4,
  .page2 .majors p {
    padding: 10px !important;
  }

  .page4 .pricing {
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 30vh;
  }

  .page4 .pricing ul,
  .page4 .pricing span {
    font-size: 1rem !important;
  }

  .page4 .pricing h2 {
    font-size: 1.25rem;
  }

  .page4 .pricing h3 {
    font-size: 1rem;
  }

  .sign-up-button,
  .nextCircle {
    border-width: 2px;
  }

  .circlePage4 {
    left: 0 !important;
    right: 0 !important;
  }

  .page4 .pricing .bottomShadow {
    display: block;
    position: fixed;
    top: 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 66%, rgba(255,255,255,0) 100%);
    width: 100%;
    height: 50vh;
    z-index: 5;
    margin-top: 52vh;
  }

  .page4 .pricing .percents h4 {
    font-size: 1rem;
  }

  .page2 .overlay .majors {
    gap: 20px;
  }

  .page2 .overlay .major {
    border-radius: 20px;
    width: 100%;
  }

  .page2 .overlay .major h3 {
    font-size: 1rem;
  }

  .page3 h1 {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  .body {
    font-size: 0.8rem;
  }

  .circle,
  .page3 .image,
  .image-circle-one {
    height: 30vw !important;
    width: 30vw !important;
  }

  .circlePage1 {
    transform: translate(60vw, 58vh) !important;
  }

  .page3 .image {
    left: 30vw !important;
    top: 63vh !important;
  }

  .image-circle-one {
    top: 68vh;
    left: 45vw;
  }

  .circlePage2 {
    transform: translate(35vw, 40vh) !important;
  }
}