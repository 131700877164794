.page2 .sections {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65vh;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
}

.page2 {
  text-align: center;
}

.page2 .page-two-grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  align-items: start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex: 2 100%;
  transform: translateX(0);
  transition: transform 1s ease, opacity 0.3s ease;
}

.page-two-grid.t {
  position: absolute !important;
  left: 100%;
  height: 100%;
  padding: 0;
}

.page2 .page-two-grid .info-box:nth-child(2),
.page2 .page-two-grid .info-box:nth-child(4) {
  justify-self: end;
}

.nextBtn {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-size: 1.75vw;
  position: absolute;
  top: 44%;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.page2 .nextBtn:hover {
  filter: brightness(75%);
}

.page2 .nextBtn.prev {
  right: unset;
  left: 10px;
}

.nextBtn.majors {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  width: 28vw;
  height: 5vw;
  background-color: var(--primary);
  border: 4px solid var(--primary);
  color: white;
  border-radius: 40vw;
  position: absolute;
  font-size: 2vw;
  transition: color 0.5s ease, background-color 0.5s ease;
  z-index: 10;
  height: 5.61vw;
  top: 85vh;
  left: 20vw;
}

.nextBtn.majors:hover {
  background-color: white;
  color: var(--primary);
}

.nextPage2 {
  left: 55vw !important;
}

.page2 .overlay {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 60;
  background-color: rgba(145, 145, 145, 0.448);
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0%;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  text-align: left;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page2 .overlay.show {
  opacity: 100%;
  visibility: visible;
}

.page2 .overlay h2 {
  color: var(--primary);
  margin-top: 0;
}

.page2 .overlay div {
  background-color: white;
  border-radius: 10px;
  color: black;
  box-sizing: border-box;
  padding: 10px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100%;
  position: relative;
  overflow-x: hidden;
}

.page2 .overlay div .body {
  padding: 0 20px 0 20px;
  text-align: left;
  font-size: 1.75vw;
}

.page2 .overlay .close {
  cursor: pointer;
  background-color: white;
  border-radius: 40px;
  padding: 0 10px 0 10px;
  border: none;
  font-size: 1.5rem;
  color: var(--primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.page2 .overlay svg rect {
  fill: var(--primary);
  opacity: 100%;
}

.page2 .overlay svg rect:first-child {
  fill: var(--primary);
  transform: rotate(45deg) translate(0, 33px);
  transform-origin: center center;
}

.page2 .overlay svg rect:last-child {
  fill: var(--primary);
  transform: rotate(-45deg) translate(0, -30px);
  transform-origin: center center;
}

.page2 .overlay .majors {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  align-items: flex-start;
  flex-wrap: wrap;
  position: static;
}

.page2 .overlay .major {
  background-color: rgb(235, 235, 235);
  border-radius: 30px;
  padding: 0;
  box-sizing: border-box;
  align-items: flex-start;
  width: 20vw
}

.page2 .overlay .major h3 {
  color: white;
  background-color: var(--primary);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.page2 .overlay .major h4 {
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
}

.page2 .overlay .major p {
  padding: 20px;
  margin: 0;
}

.page2 .overlay .major .body {
  margin: 0;
  padding: 0 20px 20px 20px !important;
}